import styled from "styled-components"
import media from "styled-media-query"

export const Produtos = styled.section`
  padding-bottom: 10rem;
  padding-top: 1rem;

  ${media.lessThan(`large`)`
    padding-bottom: 2rem;
  `}
`

export const ProdutosWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
  `}
`

export const ProdutosHeader = styled.div`
  display: block;
  width: 100%;
  margin: 4rem auto 0;
  padding: 1.5rem 2rem 1.5rem 0;
`

export const ProdutosHeaderTitle = styled.h1`
  font-size: 2rem;
  text-align: left;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export const ProdutosHr = styled.hr`
  width: 3rem;
  height: 1px;
  background: var(--black);
  text-align: left;
  margin: 0;
`

export const ProdutosContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const ProdutosMenu = styled.menu`
  width: 15vw;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;

  ${media.lessThan("large")`
    width: 90vw;
    margin: 0 auto;
  `}
`

export const ProdutosMenuItem = styled.button`
  text-decoration: none;
  margin-bottom: 1.5rem;
  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.active {
    color: var(--white);
    border: 1px solid var(--orange);
    background-color: var(--orange);
    box-shadow: 0px 3px 11px var(--black);
  }

  ${media.lessThan("large")`
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  `}
`

export const ProdutosGaleria = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85vw;

  ${media.lessThan("large")`
    width: 90vw;
    margin: 0 auto;
  `}
`

export const ProdutosImgBox = styled.div`
  width: 30%;
  margin: 0.5rem;

  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 1rem;
  `}
`

export const ProdutosImg = styled.img`
  width: 100%;
  height: auto;
`

export const ProdutosImgText = styled.p`
  display: block;
  background-color: var(--grey);
  color: var(--white);
  padding: 1.5rem 2rem;
  text-transform: uppercase;
  text-align: center;
`
