import React, { useState } from "react"

import * as S from "./styled"

const Produtos = () => {
  const [active, setActive] = useState("Arames")
  const [produtos, setProdutos] = useState([
    {
      name: "Arame recozido 12",
      photo: "img/produtos/arames/recozido12.jpg",
    },
    {
      name: "Arame recozido 18",
      photo: "img/produtos/arames/recozido18.jpg",
    },
  ])
  const buttons = [
    "Arames",
    "Telas e Malhas",
    "Treliças",
    "Ferragem Armada",
    "Vergalhões",
  ]
  function someFunct(name) {
    setActive(name)
    if (name === "Arames") {
      setProdutos([
        {
          name: "Arame Recozido 12",
          photo: "img/produtos/arames/recozido12.jpg",
        },
        {
          name: "Arame Recozido Torcido 18",
          photo: "img/produtos/arames/recozido18.jpg",
        },
      ])
    }
    if (name === "Telas e Malhas") {
      setProdutos([
        {
          name: "Malha pop leve (20x20x3,4) (2,00x3,00)",
          photo: "img/produtos/malhas/malha.jpg",
        },
        {
          name: "Malha pop Média (15x15x3,4) (2,00x3,00)",
          photo: "img/produtos/malhas/malha.jpg",
        },
        {
          name: "Malha pop Pesada (10x10x4,2) (2,00x3,00)",
          photo: "img/produtos/malhas/malha.jpg",
        },
        {
          name: "Malha reforçada (15x15x4,2) (2,00x3,00)",
          photo: "img/produtos/malhas/malha.jpg",
        },
        {
          name: "Tela Nervurada Q92 (15x15x4,2) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
        {
          name: "Tela Nervurada Q196 (10x10x5,0) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
        {
          name: "Tela Nervurada Q138 (10x10x4,2) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
        {
          name: "Tela Nervurada (10x10x4,2) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
        {
          name: "Tela Nervura (15x15x3,4) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
        {
          name: "Tela Nervura (10x10x6,3) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
        {
          name: "Tela Nervura (10x10x5,6) (2,45x6,00)",
          photo: "img/produtos/telas/tela.jpg",
        },
      ])
    }
    if (name === "Treliças") {
      setProdutos([
        {
          name: "Treliça H8",
          photo: "img/produtos/trelicas/trelica.jpg",
        },
        {
          name: "Treliça H12",
          photo: "img/produtos/trelicas/trelica.jpg",
        },
        {
          name: "Treliça H16",
          photo: "img/produtos/trelicas/trelica.jpg",
        },
      ])
    }
    if (name === "Ferragem Armada") {
      setProdutos([
        {
          name: "Ferragem Armada",
          photo: "img/produtos/ferragem/ferragem01.jpg",
        },
        {
          name: "Ferragem Armada",
          photo: "img/produtos/ferragem/ferragem02.jpg",
        },
        {
          name: "Ferragem Armada",
          photo: "img/produtos/ferragem/ferragem03.jpg",
        },
      ])
    }
    if (name === "Vergalhões") {
      setProdutos([
        {
          name: "Vergalhão Barra Redonda 10mm Lisa",
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 6,3mm (1/4")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 10mm (3/8")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 12,5mm (1/2")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 16mm (5/8")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 20mm (3/4")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 25mm (1")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 50 DN 8mm (5/16")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: 'Vergalhão CA 60 DN 4,2mm (3/16")',
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: "Vergalhão CA 60 DN 5mm",
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
      ])
    }
  }
  return (
    <S.Produtos>
      <S.ProdutosWrapper>
        <S.ProdutosHeader>
          <S.ProdutosHeaderTitle>Produtos</S.ProdutosHeaderTitle>
          <S.ProdutosHr />
        </S.ProdutosHeader>
        <S.ProdutosContainer>
          <S.ProdutosMenu>
            {buttons.map((name, index) => {
              return (
                <S.ProdutosMenuItem
                  key={index}
                  onClick={() => someFunct(name)}
                  className={active === name ? "active" : ""}
                >
                  {name}
                </S.ProdutosMenuItem>
              )
            })}
          </S.ProdutosMenu>
          <S.ProdutosGaleria>
            {produtos.map((item, index) => {
              return (
                <S.ProdutosImgBox key={index}>
                  <S.ProdutosImg src={item.photo} alt="" />
                  <S.ProdutosImgText>{item.name}</S.ProdutosImgText>
                </S.ProdutosImgBox>
              )
            })}
          </S.ProdutosGaleria>
        </S.ProdutosContainer>
      </S.ProdutosWrapper>
    </S.Produtos>
  )
}

export default Produtos
