import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Produtos from "../components/Produtos"
import CallToAction from "../components/CallToAction"
import Contato from "../components/Contato"

const ProdutosPage = () => {
  return (
    <Layout>
      <SEO title="Produtos" />
      <Produtos />
      <CallToAction />
      <Contato title={false} />
    </Layout>
  )
}

export default ProdutosPage
